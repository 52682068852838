// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/roundup_logo.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".congrats-view{padding:20px 60px 40px 60px;text-align:left}.congrats-view .congrats-header{margin:0}.congrats-view .roundup-logo{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:137px 14px;background-repeat:no-repeat;background-position:50%;width:100%;height:14px;margin:0 0 20px}.congrats-view .accept{background:#000;padding:10px;min-width:90%;color:#fff;margin:auto}.congrats-view .row{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-ms-flex-direction:row;flex-direction:row}", ""]);
// Exports
module.exports = exports;
