<template>
	<screen size="small" class="congrats-view">
		<section class="user-edit-view">
			<h3>Verify Your Information</h3>
			<p>
				To meet all applicable laws, please make sure your information
				is up to date.
			</p>
			<div v-if="error" class="alert alert-error">{{ error }}</div>
			<form v-on:submit.prevent="submit">
				<ui-label>
					<ui-input
						v-model="user.first_name"
						placeholder="First Name"
					></ui-input>
				</ui-label>
				<ui-label>
					<ui-input
						v-model="user.last_name"
						placeholder="Last Name"
					></ui-input>
				</ui-label>
				<ui-label>
					<ui-input
						v-model="user.email"
						placeholder="Email"
					></ui-input>
				</ui-label>
				<ui-label>
					<ui-input
						type="number"
						v-model="user.phone"
						placeholder="Cell/ Direct Phone"
					></ui-input>
				</ui-label>
				<ui-label>
					<ui-input
						v-model="user.title"
						placeholder="Title"
					></ui-input>
				</ui-label>
				<ui-label
					v-bind:style="{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center'
					}"
				>
					<ui-toggle
						v-bind:style="{ flex: 1 }"
						@input="checked('request_contact')"
						:checked="user.request_contact"
					>
					</ui-toggle>
					<p v-bind:style="{ flex: 4 }">
						I want to be contacted by a growth specialist.
					</p>
				</ui-label>
				<ui-label
					v-bind:style="{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center'
					}"
				>
					<ui-toggle
						v-bind:style="{ flex: 1 }"
						@input="checked('email_subscribe')"
						:checked="user.email_subscribe"
					>
					</ui-toggle>
					<p v-bind:style="{ flex: 4 }">
						I would like to receive updates and news about RoundUp
						App.
					</p>
				</ui-label>
				<ui-button
					type="success"
					native-type="submit"
					:loading="isSaving"
					>Save</ui-button
				>
			</form>
		</section>
	</screen>
</template>

<script>
import Screen from '../../ui/Screen'
import { mapActions } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
import UiButton from '../../ui/Button'
import UiLabel from '../../ui/Label'
import UiInput from '../../ui/Input'
import UiToggle from '../../ui/Toggle'
import router from '../../../router'

export default {
	name: 'congrats-view',
	components: { UiButton, UiInput, UiLabel, Screen, UiToggle },
	data() {
		return {
			isSaving: false,
			user: cloneDeep(this.$store.state.me.current),
			error: ''
		}
	},
	methods: {
		...mapActions(['updateUser', 'getCurrentUser']),
		submit() {
			this.update()
		},
		update() {
			this.isSaving = true
			this.user.verified = true
			this.updateUser(this.user)
				.then(() => {
					this.getCurrentUser().then(() => {
						this.isSaving = false
						router.push({ name: 'reports' })
					})
				})
				.catch(({ message }) => {
					this.isSaving = false
					this.error = message
				})

			return false
		},
		checked(name) {
			this.user[name] = !this.user[name]
		}
	}
}
</script>

<style lang="scss">
@import '../../../styles/variables';

.congrats-view {
	padding: 20px 60px 40px 60px;
	text-align: left;
	.congrats-header {
		margin: 0px;
	}
	.roundup-logo {
		background: url('../../../assets/roundup_logo.svg');
		background-size: 137px 14px;
		background-repeat: no-repeat;
		background-position: center;
		width: 100%;
		height: 14px;
		margin: 0px 0px 20px;
	}
	.accept {
		background: #000;
		padding: 10px;
		min-width: 90%;
		color: #fff;
		margin: auto;
	}
	.row {
		display: flex;
		flex-direction: row;
	}
}
</style>
